import * as React from "react";
import ProjectSection from "../components/ProjectSection";
import { Link } from "gatsby";
import LinkArrowSvg from "../images/link-arrow";
import ClientsSection from "../components/home/ClientSection";
import Banner from "../components/Banner";
// import KnowMoreSection from "../components/KnowMoreSection";
import Faq from "../components/Faq";
import { Seo } from "../components/seo";
import LetsConnect from "../components/LetsConnect";
import KnowAbout from "../components/KnowAbout";
import WhatWeAre from "../components/WhatWeAre";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
const Questions = [
  "Which industries does your UI UX Design Agency in Surat cater to?",
  "What design tools and technologies do your company typically use?",
  "How long does your UI UX Agency typically take to complete a design project?",
  "How can we initiate a collaboration with your UI UX Design Agency?",
  "What are the charges for your UI UX Design Services?",
];

const SuratPage = () => {
  const banner = {
    title: `UI UX Design Agency in Surat`,
    content:
      "At our UI UX design agency in Surat, we are committed to enhancing your brand's loyalty and retention through a user-friendly digital experience.",
    img: ["why-octet-surat.webp"],
    imageAlt: "Octet Design Studio- UI UX Design Agency in Surat",
    imageTitle: "UI UX Design Company, Surat",
  };
  const projectData = [
    {
      title: "iGaming",
      desc: "Platform To Track Affiliates With Omni-Channel Tracking Feature",
      Industry: "SaaS",
      firstAlt: "UI UX Screen to manage and track affiliates ",
      firstTitle: "UI Screen to Manage Affiliates ",
      secondAlt:
        "Dashboad UI Design to track the performance of affiliate activities",
      secondTitle: "Dashboard UI Design to Track Affiliates",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Angular Development",
          link: "/angularjs-development/",
        },
      ],
      link: "/project/igaming/",
      images: ["igaming1.webp", "igaming2.webp"],
    },
    {
      title: "Unilogix App",
      desc: "A Logistics Insights Application",
      Industry: "Logistics",
      firstAlt: "Mobile App UI UX Design to track logistics performance",
      firstTitle: "Mobile UI Design to Track Logistics",
      secondAlt: "Mobile App UI UX Design to check order details",
      secondTitle: "Mobile UI Design to View Order Details",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp"],
    },
  ];
  const faqData = [
    {
      para: [
        `Being one of the best UI UX agencies in Surat, our services cater to a diverse range of industries, including SaaS, enterprise, and B2B, ensuring our design solutions are tailored to each sector's unique needs.`,
      ],
    },
    {
      para: [
        `Octet leverages various industry-standard design tools and technologies, ensuring our designs are visually appealing, technically sound, and compatible with your project requirements.`,
      ],
    },
    {
      para: [
        `The duration of the UI UX design project depends on its complexity and scope.`,
        `Various other factors influence the project timeline, including resource availability, project changes, techniques, budget, etc. However, the duration can range from a few weeks to several months.`,
        `At our UI UX design agency in Surat, we work closely to understand and fulfill your design requirements. We aim to ensure a thorough and efficient process while meeting your timeline.`,
      ],
    },
    {
      para: [
        `If you want our expert UI UX solutions, fill out this <a href="/contact-us/" title="Contact Us" target="blank">contact form</a>.  We will respond within a few hours to discuss your project's challenges and possible solutions.`,
      ],
    },
    {
      para: [
        `Our pricing is tailored to the specific needs and scope of each project. We understand that every project is unique, so we offer a flexible pricing model based on factors such as project complexity, size, and the required range of services.`,
        `To provide an accurate quote, we'd be happy to discuss your project in detail and understand your requirements.`,
        `Please feel free to <a href="/contact-us/" title="Contact Us" target="blank">reach out to us</a> for a personalized quote. We'll work closely with you to ensure our services align with your budget and project goals.`,
      ],
    },
  ];
  const lastLength = projectData;
  const info = {
    title: "Get Started with the best UI UX Agency",
    link: "/contact-us/",
    text: "Contact Us",
  };
  const whatWeAreData = {
    title: "Get Full-Services with the Best UI UX Agency",
    des: "As a top Surat UI UX design company, we believe that experiences can alter SaaS, Enterprise, and B2B businesses.",
    items: [
      {
        title: "User Research",
        content:
          "Octet utilizes various techniques to collect data and gain insights that help us create products that meet our customers' needs. We aim to ensure that your product succeeds and exceeds your expectations.",
        link: "/user-research/",
        linkText:
          'To achieve long-term benefits, our <a href="/user-research/" title="User Research Agency">user research agency</a> streamlines your resource-intensive processes.',
      },
      {
        title: "UI UX Designing",
        content:
          "Being the best UI UX design agency, we systematically design and craft interactive, visually captivating interfaces that guarantee a seamless and straightforward user experience.",
        link: "/ui-ux-designing/",
        linkText:
          'For better performance, our <a href="/ui-ux-designing/" title="UI UX design agency">UI UX Design Agency</a>, may create personalized solutions for your company.',
      },
      {
        title: "Usability Testing",
        content:
          "Our UI UX design solutions ensure user-friendly, accessible products. We rigorously test with various tools and techniques to identify areas for improvement and maintain consistency across all our products.",
        link: "/usability-testing/",
        linkText:
          'To create an interface that is easy to use, our <a href="/usability-testing/" title="Usability Testing Agency">usability testing company</a> monitors how you use it and spots problems.',
      },
      {
        title: "UI Development",
        content:
          "Our UI development is earmarked to deliver exceptional user experiences. We ensure that every design is meticulously transformed into a template with captivating visual components fully integrated into the Java framework.",
        link: "/ui-development/",
        linkText:
          'Our <a href="/ui-development/" title="UI Development Company">UI Development Company</a> uses its proficiency in multiple programming languages to create functional user interfaces.',
      },
    ],
    mainLink: {
      link: "/services/",
      text: "View All UI UX Services",
    },
  };
  const faqDes = `We have compiled frequently asked questions to provide informative answers about our UI UX design agency in Surat, the design process, and pricing. If you still have any questions, don’t hesitate to <a href="/contact-us/" title="Contact Us"> contact us</a>.`;
  const knowAboutDes = [
    "Octet is a prominent UI UX design agency in Surat. We transform SaaS, Enterprise, and B2B products through a wise, proactive, and proficient approach. Our flexible, lean, and agile UX UI solutions empower businesses to embark on a revolutionary journey and realize their full potential. We deliver results beyond expectations with unmatched expertise and the latest industry best practices. ",
  ];
  const knowAboutTitle = "Partner with our UI UX design agency in Surat";
  const commonContent = {
    title: "Experience Excellence With the best UI UX agency",
    content: [
      {
        title: "Scalable Solution",
        text: "We create future-ready interfaces using our creative UI UX solutions to ensure that they adapt to changing needs.",
      },
      {
        title: "Cross-Platform Consistency",
        text: "We design consistent interfaces across platforms and devices, creating a cohesive brand identity.",
      },
      {
        title: "Increased Engagement",
        text: "We create and refine designs to promote engagement, reduce bounce rates, and boost user happiness.",
      },
      {
        title: "Data-Driven Insights",
        text: "We use cutting-edge techniques and technologies to analyze customer preferences and adapt designs to match their needs.",
      },
    ],
  };
  const commonContent1 = {
    title: "Increase Outcome with Octet’s Design Process",
    para: ['Being the best UI UX Agency in Surat, we create remarkable experiences via innovation and personalization'],
    content: [
      {
        title: "1. Briefing",
        text: "To establish the nature of our collaboration, our UI UX design agency in Surat carries out design audits, competitive analyses, discussions, and usability testing that clarify product and business difficulties.",
      },
      {
        title: "2. Defining",
        text: "We determine your target market by interacting with your customers and utilizing various techniques to grasp their issues fully. As a team, we define the project, set deadlines, write down objectives, and decide on metrics to help you succeed.",
      },
      {
        title: "3. Strategizing",
        text: "Our UI UX design agency strategizes the user experience flow, develops guidelines, and structures information architecture to organize your website's content and effectively achieve its purpose.",
      },
      {
        title: "4. Sketching",
        text: "We formulate an effective visual hierarchy for your app or website to facilitate comprehension. We aim to provide you with a visually appealing, user-friendly interface that enhances the user experience and maximizes your product's potential.",
      },
      {
        title: "5. Designing & Testing",
        text: "After finalizing the layout and designs, we develop a prototype and test the end product's functionality. This enables our design agency to evaluate the final product and ensure it meets the required standards and specifications.",
      },
    ],
  };
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "Which industries does your UI UX Design Agency in Surat cater to?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Being one of the best UI UX agencies in Surat, our services cater to a diverse range of industries, including SaaS, enterprise, and B2B, ensuring our design solutions are tailored to each sector's unique needs."
                }
              },{
                "@type": "Question",
                "name": "What design tools and technologies do your company typically use?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Octet leverages various industry-standard design tools and technologies, ensuring our designs are visually appealing, technically sound, and compatible with your project requirements."
                }
              },{
                "@type": "Question",
                "name": "How long does your UI UX Agency typically take to complete a design project?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The duration of the UI UX design project depends on its complexity and scope.
            Various other factors influence the project timeline, including resource availability, project changes, techniques, budget, etc. However, the duration can range from a few weeks to several months.
            At our UI UX design agency in Surat, we work closely to understand and fulfill your design requirements. We aim to ensure a thorough and efficient process while meeting your timeline."
                }
              },{
                "@type": "Question",
                "name": "How can we initiate a collaboration with your UI UX Design Agency?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "If you want our expert UI UX solutions, fill out this contact form. We will respond within a few hours to discuss your project's challenges and possible solutions."
                }
              },{
                "@type": "Question",
                "name": "What are the charges for your UI UX Design Services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our pricing is tailored to the specific needs and scope of each project. We understand that every project is unique, so we offer a flexible pricing model based on factors such as project complexity, size, and the required range of services.
            To provide an accurate quote, we'd be happy to discuss your project in detail and understand your requirements.
            Please feel free to contact us for a personalized quote. We'll work closely with you to ensure our services align with your budget and project goals."
                }
              }]
            }                              
          `}
        </script>
      </Helmet>
      <Layout>
        <Banner
          content={banner}
          page={"ahmedabad-home"}
          location="Office no. 227, Green Palladia, Near Palanpur Canal Rd,
            Palanpur Jakatnaka, 
            Surat, Gujarat 395005
        "
        />
        <WhatWeAre data={whatWeAreData} />
        <KnowAbout
          des={knowAboutDes}
          title={knowAboutTitle}
          imageAlt={"Logo of UI UX Design Company in Surat"}
          imageTitle={"UI UX Design Studio's Logo"}
        />
        <CommonServiceComponent data={commonContent} mode={"grey"} nopara />
        <CommonServiceComponent
          data={commonContent1}
          mode={"light"}
          list="single"
        />
        <section className="project-section lg:py-[200px] md:pb-[125px] md:pt-[140px] pt-[100px] pb-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
          <div className="container">
            <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
              Our Projects in <span className="h1-span">UI UX Design</span>
            </h2>
          </div>
          {projectData.map((data, index) => (
            <div key={index}>
              <ProjectSection num={index} lastLength={lastLength} data={data} />
            </div>
          ))}
          <div className="container lg:mt-[-100px] md:mt-[-35px] mt-[-40px]">
            <Link
              title="UI UX Design Portfolio"
              to="/projects/"
              className="animated-link font-medium font-heading text-[20px] md:text-[22px] lg:text-[30px] md:leading-[1.33] leading-[normal] tracking-[.05em] flex items-center gap-[32px] md:gap-[38px]"
            >
              <p className="max-w-[262px] md:max-w-none w-auto font-heading">
                See more work by our UI UX design agency{" "}
              </p>
              <span>
                <LinkArrowSvg />
              </span>
            </Link>
          </div>
        </section>
        <ClientsSection title={'Trusted by Clients Worldwide'} />
        <LetsConnect data={info} />
        <Faq section Questions={Questions} faqData={faqData} des={faqDes} />
        {/* <KnowMoreSection nin="Contact Us" Include="services" /> */}
      </Layout>
    </>
  );
};

export default SuratPage;

export const Head = () => (
  <Seo
    title="UI UX Design Company in Surat- Octet"
    description="Transform your SaaS, B2B and Enterprise products with leading UI UX Design Agency in Surat. We Craft engaging & user-centric experiences for your product!"
  />
);
